import { withPrefix } from 'gatsby'
import React, { FC } from 'react'
import { Col, Row } from 'styled-bootstrap-grid'

import FB from '../../images/fb.svg'
import LI from '../../images/in.svg'
import TW from '../../images/tw.svg'
import RSS from '../../images/rss.svg'
import IG from '../../images/ig.svg'
import parser from '../../utils/htmlParser'
import SiteLink from '../common/SiteLink'
import {
  ExternalLink,
  FooterBottomNavigation,
  Section,
  SocialIcon,
  SocialImage,
} from './Footer.styles'
import { FooterProps } from './Footer'
import { SocialLinks } from './types'

type Props =  {
  storesLinks: Record<keyof FooterProps['content']['socialApp']['app'], string>
  legalMenuItems: FooterProps['legal']['menuItems']
  socialLinks: SocialLinks
}

export const Links: FC<Props> = ({
  storesLinks,
  legalMenuItems,
  socialLinks,
}) => (
  <Section noBorder>
    <Col col={12} lg={4}>
      <Row justifyContent="center">
        <ExternalLink href={storesLinks.apple}>
          <SocialImage
            src={withPrefix(`/${process.env.GATSBY_LANG}/appstore.png`)}
            alt="App Store"
            loading="lazy"
          />
        </ExternalLink>
        <ExternalLink href={storesLinks.android}>
          <SocialImage
            src={withPrefix(`/${process.env.GATSBY_LANG}/googleplay.png`)}
            alt="Google Play"
            loading="lazy"
          />
        </ExternalLink>
      </Row>
    </Col>
    <Col col={12} lg={4}>
      <FooterBottomNavigation data-nosnippet>
        {!!legalMenuItems && (
          <ul>
            {legalMenuItems.nodes.map(({ label, url, menuItemId }) => (
              <li key={menuItemId}>
                <SiteLink to={url}>{parser(label)}</SiteLink>
              </li>
            ))}
          </ul>
        )}
      </FooterBottomNavigation>
    </Col>
    <Col col={12} lg={4}>
      <Row justifyContent="center" xlJustifyContent="end">
        <SocialIcon src={FB} href={socialLinks.facebook} target="_blank" />
        <SocialIcon src={TW} href={socialLinks.twitter} target="_blank" />
        <SocialIcon src={RSS} href={socialLinks.rss} target="_blank" />
        <SocialIcon src={LI} href={socialLinks.linkedin} target="_blank" />
        <SocialIcon src={IG} href={socialLinks.instagram} target="_blank" />
      </Row>
    </Col>
  </Section>
)
