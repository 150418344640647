import React, { FC } from 'react'

import BootstrapColumns from '../blocks/mfblocks/BootstrapColumns'
import { FooterWrapper } from './Footer.styles'
import { ContactInfo } from './ContactInfo'
import { Disclaimer } from './Disclaimer'
import { Menu } from './Menu'
import { Legal } from './Legal'
import { Links } from './Links'
import { FooterContent, FooterLegalInfo, FooterMenu } from './types'

export type FooterProps = {
  menu: FooterMenu
  legal: FooterLegalInfo
  content: FooterContent
}

export const Footer: FC<FooterProps> = ({
  menu,
  legal,
  content: {
    legal: { section: legalSections },
    business: { copyright, registered },
    contactBar: { cta, emailAddress, tel },
    disclaimer,
    socialApp: {
      app: {
        android: { link: androidLink },
        apple: { link: appleLink },
      },
      social,
    },
  },
}) => (
  <FooterWrapper>
    {/*  @ts-expect-error - Since this is a JS component, all props are marked as mandatory even if they are actually not. */}
    <BootstrapColumns>
      <ContactInfo cta={cta} emailAddress={emailAddress} tel={tel} />
      <Disclaimer disclaimer={disclaimer.text} />
      <Menu menu={menu} copyright={copyright} registered={registered} />
      <Legal legalSections={legalSections} />
      <Links
        storesLinks={{ apple: appleLink, android: androidLink }}
        legalMenuItems={legal?.menuItems}
        socialLinks={social}
      />
    </BootstrapColumns>
  </FooterWrapper>
)
