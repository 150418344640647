import React, { FC } from 'react'

import { Grid, GridItem } from 'mf-ui-components'

import parser from '../../utils/htmlParser'
import { Section, StyledLink } from './Footer.styles'
import { FooterContent } from './types'

type Props = FooterContent['contactBar']

export const ContactInfo: FC<Props> = ({ cta, tel, emailAddress }) => (
  <Section>
    <Grid
      className="contact-info"
      flow={{ sm: 'column', lg: 'row' }}
      justifyContent="space-between"
      alignItems={{ sm: 'center', lg: 'center' }}
      data-nosnippet
    >
      <GridItem width={{ sm: 9, lg: 8 }}>{parser(cta)}</GridItem>

      <Grid gap="lg" style={{ width: 'fit-content' }}>
        <StyledLink className="tel-number" href={`tel:${tel}`}>
          {tel}
        </StyledLink>
        <StyledLink className="tel-number" href={`mailto:${emailAddress}`}>
          {emailAddress}
        </StyledLink>
      </Grid>
    </Grid>
  </Section>
)
