// NOTE: `hideAfter` and `hideBefore` are string in `Y-m-d H:i:s` format,
// which is not guaranteed to be a valid format for the `Date` constructor on
// all JS runtimes. We therefore convert it to `Y-m-dTH:i:s` to make it
// ISO8601-compliant.
export function shouldDisplay({
  isClosed,
  htmlContent,
  hideAfter,
  hideBefore,
  currentDate,
  currentPageSlug,
  blacklistedSlugs,
}: {
  isClosed: boolean
  htmlContent?: string
  hideAfter?: string
  hideBefore?: string
  currentDate: Date
  currentPageSlug: string
  blacklistedSlugs: string[]
}): boolean {
  return (
    // The user has not closed the banner.
    !isClosed &&
    // The banner has actual content.
    !!htmlContent?.length &&
    // The ending date is not defined, or it's in the future.
    (!hideAfter || new Date(hideAfter) > currentDate) &&
    // The starting date is not defined, or it's in the past.
    (!hideBefore || new Date(hideBefore) < currentDate) &&
    !blacklistedSlugs.includes(currentPageSlug)
  )
}
