import React, { FC } from 'react'

import { MenuNode } from '../types'
import {
  Caret,
  LinkBody,
  LinkWrapper,
  StyledLink,
} from './NavigationBarLink.styles'

const isTel = (url: string) => url.startsWith('tel://')

type Props = { node: MenuNode; hasChildren: boolean }

export const NavigationBarLink: FC<Props> = ({ node, hasChildren }) => (
  <StyledLink hasChildren={hasChildren} to={node.url}>
    <LinkWrapper isTel={isTel(node.url)} hasChildren={hasChildren}>
      <LinkBody hasChildren={hasChildren} data-nosnippet>
        {node.label}
        <Caret isTel={isTel(node.url)} hasChildren={hasChildren} />
      </LinkBody>
    </LinkWrapper>
  </StyledLink>
)
