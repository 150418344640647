import * as React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'

import {
  cookiesConsentExpressed,
  getInitialCookies,
  updateCookiesConsent,
} from '../../libs/cookie-consent'
import { CookieBanner } from 'mf-ui-components'

import { getContentCompact, getContentDetail } from './content'

const CookiesComponent: React.FC<WrappedComponentProps> = ({ intl }) => {
  const [consentExpressed, setConsentExpressed] = React.useState<boolean>(
    cookiesConsentExpressed(getInitialCookies())
  )
  const [showCookieBanner, setShowCookieBanner] = React.useState(false)

  React.useEffect(() => {
    !consentExpressed ? setShowCookieBanner(true) : setShowCookieBanner(false)
  }, [consentExpressed])

  if (showCookieBanner) {
    return (
      <div style={{ display: 'contents' }} data-nosnippet>
        <CookieBanner
          cookies={getInitialCookies()}
          contentCompact={getContentCompact(intl)}
          contentDetail={getContentDetail(intl)}
          hideBackdrop={
            window.location.pathname.includes('cookie') ? true : false
          }
          onSubmitClick={(cookies: any, grantAll: any) => {
            updateCookiesConsent(cookies, grantAll)
            setConsentExpressed(true)
          }}
        />
      </div>
    )
  }

  return null
}

export const Cookies = injectIntl(CookiesComponent)
