import React, { FC } from 'react'

import { MenuNode } from '../types'
import {
  ChildElement,
  ChildlessItem,
  Container,
  SecondaryHeading,
  Separator,
} from './MobileMenuItem.styles'
import { ContactUsMenuItem } from './ContactUsMenuItem'
import { MobileMenuAccordion } from './MobileMenuAccordion'

type Props = {
  node: MenuNode
  nestingLevel?: number
}

export const MobileMenuItem: FC<Props> = ({
  node: { label, childItems, url },
  nestingLevel = 0,
}) => {
  const isNested: boolean = nestingLevel > 0
  const hasChildren: boolean = childItems.nodes.length > 0

  if (!hasChildren)
    return (
      <ChildlessItem className="child" to={url}>
        {label}
      </ChildlessItem>
    )

  const childItemsList = (
    <div>
      {childItems.nodes.map((child, index) =>
        child.url === '#separator' ? null : child.url === '#contactus' ? (
          <ContactUsMenuItem key={index} />
        ) : child.childItems?.nodes.length > 0 ? (
          <MobileMenuItem
            key={index}
            node={child}
            nestingLevel={nestingLevel + 1}
          />
        ) : (
          <ChildElement
            key={index}
            className="child"
            to={child.url}
            data-nosnippet
          >
            {child.label}
          </ChildElement>
        )
      )}
    </div>
  )

  if (nestingLevel > 0)
    return (
      <Container nestingLevel={nestingLevel}>
        <SecondaryHeading data-nosnippet>
          {label.toUpperCase()}
        </SecondaryHeading>

        {childItemsList}
      </Container>
    )

  return (
    <>
      <Container nestingLevel={nestingLevel}>
        <MobileMenuAccordion nestingLevel={nestingLevel} title={label}>
          {childItemsList}
        </MobileMenuAccordion>
      </Container>

      {!isNested && <Separator />}
    </>
  )
}
